import React from 'react'
import { Link } from 'gatsby'

// markup
const Footer = ({landing}) => {
  return (
    <div className="section" id="footer">
      <div className="footer-wrapper">
        <div className="left-foot">
          <img src="/logos/white-logo.svg" alt="PaverOps" />
          {landing && 
            <Link to="/app">
              <button className="footer-login primary">Login</button>
            </Link>
          }
        </div>
        <div className="right-foot">
          <label>©Paverops</label>
        </div>
      </div>
    </div>
  )
}

export default Footer
