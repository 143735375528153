import React from 'react'
import { Link } from 'gatsby'

// markup
const Contact = ({text, register, contact}) => {
  return (
    <div className="contact"> 
      <p>{text}</p>
      {!contact ? (
        <Link to={register ? "/contact/" : "/support/feedback"}>
          <button className="primary">Contact us</button>
        </Link>
      ) : (
        <p className="contact-small">Members can log in and visit the support hub <Link to="/support/">here</Link>.</p>
      )}
    </div>
  )
}

export default Contact
