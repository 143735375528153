import React, {useEffect, useState, useRef, Fragment} from 'react'
import Layout from '../components/layout'
import Nav from '../components/ui/nav'
import ContactBox from '../components/ui/contact'
import { navigate } from 'gatsby'
import ComboBox from 'react-responsive-combo-box'
import Footer from '../components/landing/footer'

// Get the URLs depending on our environment
let lambdaUri = 'http://localhost:8888'

const currentEnv = process.env.GATSBY_ENV
if (currentEnv === "prod"){
  lambdaUri = 'https://www.paverops.com' 
}
if (currentEnv === "stage"){
  lambdaUri = 'https://stage--paverops.netlify.app' 
}

// markup
const Contact = () => {
  let [supportText, setSupportText] = useState("")
  let [submitProgress, setSubmitProgress] = useState(false)
  let [supportEmail, setSupportEmail] = useState("")

  // Check if submit should be disabled
  let submitClass = "primary"
  if (!supportText || !supportEmail){
    submitClass += " disabled"
  }

  return (
    <Layout>
      <main>
        <div className="non-landing">
          <Nav page={"faq"} items={[
            {url: "/#register", text: "Request demo"},
            {url: "/faq/", text: "FAQs"},
            {url: "/app/", text: "Login"}
          ]} />
          <div className="topper">
            <div className="img-wrapper">
              <img src="/images/topperfaq.jpg" alt="An angular slice of a road" />
            </div>
            <div className="deck-wrapper">
              <div className="deck">
                <h1>
                  Contact Us
                </h1>
              </div>
            </div>
          </div>
        </div>
        <section className="app-body support">
          <div className="support-container">
            <h2 className="email-notice">Fill out the form below and a member of our team will be in touch to assist:</h2>
            <div className="support-form-wrapper">
              <label htmlFor="support-email">Email address:</label>
              <input type="text" name="email" id="support-email" value={supportEmail} placeholder="Email address" disabled={['done', 'next'].indexOf(submitProgress) !== -1} onChange={(e) => {
                setSupportEmail(e.currentTarget.value)
              }} />
              <label htmlFor="support-text">Message:</label>
              <textarea placeholder="Enter your message here" id="support-text" value={supportText} disabled={['done', 'next'].indexOf(submitProgress) !== -1} onChange={(e) => {
                setSupportText(e.currentTarget.value)
              }}>
              </textarea>
              <button className={submitClass} onClick={() => {
                // Exit early if we're already in progress
                if (!submitProgress && submitClass === "primary"){
                  setSubmitProgress("progress")
                  // Get form data ready to send
                  let formData = new FormData()
                  formData.append('email', supportEmail)
                  formData.append('report', supportText)
                  if (typeof navigator !== "undefined"){
                    formData.append('useragent', navigator.userAgent)
                  }
                  
                  // Send the support req over
                  fetch(lambdaUri+'/.netlify/functions/send-email', {
                    method: 'post',
                    body: formData
                  })
                  .then(response => response.json())
                  .then(data => {
                    // Just set to done so we can't be spammed with requests
                    setSubmitProgress("done")
                    // Prep next message
                    setTimeout(() => {
                      setSubmitProgress("next")
                    }, 2000)
                  })
                } else if (submitProgress === "next") {
                  // Handle clearing the content for a new message
                  setSupportText("")

                  // Ready to write again
                  setSubmitProgress(false)
                }
              }}>
                {submitProgress ? (
                  <Fragment>
                    {submitProgress === "progress" &&
                      <span className="loading">
                        <img src="/loading.png" alt="Loading icon" />
                      </span>
                    }
                    {submitProgress === "done" &&
                      <span>Feedback sent!</span>
                    }
                    {submitProgress === "next" &&
                      <span>Write new message</span>
                    }
                  </Fragment>
                ) : (
                  <span>Submit</span>
                )}
              </button>
              {submitProgress === "next" &&
                <Fragment>
                  <button className="primary" onClick={() => {
                    navigate("/")
                  }}>
                    Return to site
                  </button>
                </Fragment>
              }
              <ContactBox text="Question about using the app, or need to report a bug?" contact={true} />
            </div>
            
          </div>
        </section>
        <Footer />
      </main>
    </Layout>
  )
}

export default Contact

